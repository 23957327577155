html {
  min-width: 360px;
  scroll-behavior: smooth;
}

body {
  background-color: #f0f2f5;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.icon-with-margin-right {
  margin-right: 10px;
}

/*
Modification to Ant Design
*/
.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.cinema-card .ant-card-head {
  text-align: center;
  background: #eee;
}

.cast-upload-list .ant-upload-list-item .ant-upload-list-item-info .ant-upload-list-item-name {
  display: none;
}

.custom-input-stepper {
  width: 160px;
  border: none;
  z-index: 0;
  /* background-color: blue; */
}

.custom-input-stepper.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: block !important;
}

.custom-input-stepper.ant-input-number-disabled
  .ant-input-number-handler-wrap
  .ant-input-number-handler {
  cursor: not-allowed;
  pointer-events: none;
}

.custom-input-stepper:focus,
.custom-input-stepper.ant-input-number-focused {
  border: none !important;
  box-shadow: none !important;
}

.custom-input-stepper .ant-input-number-handler-wrap {
  opacity: 1;
  width: 160px;
  height: 40px;
  position: relative;
  z-index: 1;
  border: none;
  outline: none;
  /* background-color: red; */
}

.custom-input-stepper .ant-input-number-handler-wrap .ant-input-number-handler {
  width: 40px;
  height: 40px !important;
  position: absolute;
  border: none;
  background-color: #1990ff;
}

.custom-input-stepper
  .ant-input-number-handler-wrap
  .ant-input-number-handler.ant-input-number-handler-down-disabled
  .ant-input-number-handler-down-inner {
  opacity: 0.5;
}

.custom-input-stepper
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-up-inner,
.custom-input-stepper
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-down-inner {
  font-size: 20px;
  width: 20px;
  height: 20px;
  left: calc(50% - 10px);
  right: auto;
  top: calc(50% - 5px);
  color: white;
}

.custom-input-stepper
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-down-inner {
  top: 50%;
}

.custom-input-stepper
  .ant-input-number-handler-wrap
  .ant-input-number-handler.ant-input-number-handler-up {
  right: 0;
  border: 1px solid #d9d9d9;
}

.custom-input-stepper
  .ant-input-number-handler-wrap
  .ant-input-number-handler.ant-input-number-handler-down {
  left: 0;
  border: 1px solid #d9d9d9;
}

.custom-input-stepper .ant-input-number-input-wrap {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50px;
  width: 60px;
  border: 1px solid #d9d9d9;
}

.custom-input-stepper .ant-input-number-input-wrap input {
  text-align: center;
}

.custom-icon {
  vertical-align: -4px;
}

/** File upload **/
.ant-upload-list-picture .ant-upload-list-item {
  height: 96px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  height: 72px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  height: 72px;
}

.confirm-delete-modal .ant-modal-confirm-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.confirm-delete-modal
  .ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 68px;
}

.photo-list .ant-upload-list-item-card-actions-btn {
  display: none;
}
